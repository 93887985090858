<template>
  <div class="search-box">{{placeholder}}</div>
</template>

<script>
export default {
  name: "x-search",
  data () {
    return {
    };
  },
  props: {
    placeholder: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {},
  components: {}
};
</script>

<style scoped>
.search-box {
  display: flex;
  align-items: center;
  height: 0.36rem;
  padding-left: 0.4rem;
  border-radius: 0.2rem;
  margin: 0.08rem 0.15rem 0;
  background: #eff2f6 url('~@images/tab_search_icon.png') no-repeat 0.16rem
    center/0.16rem;
  font-size: 0.12rem;
  color: #afb2b9;
}
</style>
