<template>
  <ul class="list-box">
    <li v-for="(item,index) in xhrData" :key="index" class="list-item" @click="itemClick(item);">
      <div class="list-title-box">
        <span>{{item.customerName}}</span>
        <i>{{item.customerType==1000?"机构":"经销商"}}</i>
      </div>
      <p class="list-txt">拜访时间：{{item.beginTime}}</p>
      <p class="list-txt">维护人：{{item.creator}}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "x-list-item",
  data () {
    return {
    };
  },
  props: {
    xhrData: {
      type: Array,
      default: () => []
    }
  },
  created () { },
  methods: {
    itemClick (item) {
      this.$emit("itemClick", item);
    }
  },
  components: {}
};
</script>

<style scoped>
.list-box {
  margin: 0.1rem 0.15rem 0;
  background-color: #ffffff;
  border-radius: 0.08rem;
}
.list-item {
  border-bottom: solid 0.01rem #f6f8fb;
  width: 3.21rem;
  margin: auto;
  line-height: 1;
  padding: 0.16rem 0;
}
.list-title-box {
  display: flex;
  justify-content: space-between;
}
.list-title-box span {
  width: 2.7rem;
  line-height: 0.18rem;
  font-size: 0.15rem;
  color: #30353d;
}
.list-title-box i {
  display: inline-table;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  font-size: 12px;
  color: #2b82f7;
  padding: 0.02rem 0.06rem;
}
.list-txt {
  margin-top: 0.1rem;
  font-size: 0.13rem;
  color: #93969d;
}
</style>
