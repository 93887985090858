import axios from "@/axios/axiosConfig";

export const queryList = (params) => {
  return axios({
    url: "/visit-record/queryList",
    method: "post",
    data: params
  });
};

export const addVisit = (params) => {
  return axios({
    url: "/visit-record/add",
    method: "post",
    data: params
  });
};

export const getVisit = (params) => {
  return axios({
    url: `/visit-record/get/${params.id}`,
    method: "get"
  });
};

export const delVisit = (params) => {
  return axios({
    url: `/visit-record/del/${params.id}`,
    method: "get"
  });
};

export const editVisit = (params) => {
  return axios({
    url: "/visit-record/update",
    method: "post",
    data: params
  });
};

export const unfinishedRecord = () => {
  return axios({
    url: "/visit-record/unfinishedRecord",
    method: "get"
  });
};
