<template>
  <div class="app-box">
    <x-search class="p" placeholder="请输入机构或经销商名称" @click.native="searchEvent"></x-search>
    <x-screen-time-tab @change="screenChange"></x-screen-time-tab>
    <x-pull-scroll-view ref="scrollView" :top="1.02" bg="#F6F8FB" @scrollLoadData="scrollLoadData" @refresh="refresh" @scrollBarPos="scrollBarPos">
      <x-list-item v-if="xhrData.length>0" :xhrData="xhrData" @itemClick="itemClick"></x-list-item>
      <x-no-data v-if="ajaxEnd&&!isNextPage&&xhrData.length>0"></x-no-data>
      <x-no-data-bg v-if="ajaxEnd&&xhrData.length<=0&&pageNum<=1" :tip="'还没有任何内容快新建一条吧~'"></x-no-data-bg>
    </x-pull-scroll-view>
    <i class="add-icon" @click="addEvent"></i>
  </div>
</template>

<script>
import search from "@/components/customerCenter/search";
import listItem from "@/components/visitRecord/listItem";
import screenTimeTab from "@/components/visitRecord/screenTimeTab";
import pullScrollView from "@/components/pullScrollView";
import noData from "@/components/noData";
import noDataBg from "@/components/noDataBg";
import { page } from "@/script/mixins/page";
import { queryList, unfinishedRecord } from "@/api/visitRecord";
import { cachePage } from "@/script/mixins/cachePage";
import { MessageBox } from "mint-ui";

export default {
  name: "visitRecordIndex",
  data () {
    return {
      selectObject: {
        // beginTime: new Date().getFullYear(),
        // endTime: new Date().getMonth() + 1
        beginTime: null,
        endTime: null,
        scrollPos: 0
      },
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader
    };
  },
  mixins: [page, cachePage],
  created () {
    this.getDataList();
  },
  methods: {
    refresh (done) {
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList(true, () => {
        done();
      });
    },
    scrollBarPos (scrollBarTop) {
      this.scrollPos = scrollBarTop;
    },
    screenChange (data) {
      this.selectObject.beginTime = data.beginTime;
      this.selectObject.endTime = data.endTime;
      this.pageNum = 1;
      this.ajaxEnd = false;
      this.getDataList();
    },
    getDataList (isReset = true, callback = null) {
      queryList({
        beginTime: this.selectObject.beginTime,
        endTime: this.selectObject.endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(xhr => {
        document.title = "拜访记录";
        const listData = xhr.data.records;
        this.isNextPage = listData.length >= this.pageSize;
        isReset && (this.xhrData = []);
        listData.map(item => {
          this.xhrData.push(item);
        });
        this.ajaxEnd = true;
        callback && callback();
      });
    },
    itemClick (item) {
      this.$router.push({
        path: `/visitRecord/detail/${item.id}`
      });
    },
    searchEvent () {
      if (this.isLeader) {
        this.$router.push({
          path: "/leaderVisitSearch"
        });
      } else {
        this.$router.push({
          path: "/searchVisit/visit"
        });
      }
    },
    addEvent () {
      unfinishedRecord().then(xhr => {
        if (xhr.code == 200) {
          if (xhr.data.unfinishedCount <= 0) {
            this.$router.push({
              path: "/searchPage/visitAdd"
            });
          } else {
            MessageBox("", "当前有未完成的拜访<br/><br/>请先结束拜访后再添加新的拜访");
          }
        }
      });
    }
  },
  activated () {
    this.$refs.scrollView.setScrollTopPos(this.scrollPos);
  },
  watch: {
    // $route (to) {
    //   if (this.$store.getters.getRefresh && to.name == this.$options.name) {
    //     this.pageNum = 1;
    //     this.ajaxEnd = false;
    //     this.getDataList();
    //     this.$store.commit("setRefresh", false);
    //   }
    // }
  },
  components: {
    [pullScrollView.name]: pullScrollView,
    [noData.name]: noData,
    [noDataBg.name]: noDataBg,
    [search.name]: search,
    [screenTimeTab.name]: screenTimeTab,
    [listItem.name]: listItem
  }
};
</script>

<style scoped>
.p {
  margin: 0.08rem 0.15rem;
}
</style>
