<template>
  <div ref="scrollBox" class="scroll-box" :style="style" @scroll="onScroll($event);" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
    <div class="pull-box">
      <span class="pull-txt" v-if="moveState<2">{{moveState===0?"下拉即可刷新...":"释放即可刷新..."}}</span>
      <span class="pull-txt" v-else>正在加载...</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "x-pull-scroll-view",
  data () {
    return {
      startY: 0,
      moveDistance: 0,
      moveState: 0
    };
  },
  props: {
    top: {
      type: Number,
      default: 0
    },
    bottom: {
      type: Number,
      default: 0
    },
    bg: {
      type: String,
      default: "#ffffff"
    }
  },
  methods: {
    touchStart (e) {
      this.moveDistance = 0;
      this.startY = e.targetTouches[0].clientY;
    },
    touchMove (e) {
      const scrollTop = this.$refs.scrollBox.scrollTop;
      if (scrollTop > 0) { return; }
      const move = (e.targetTouches[0].clientY - this.startY) / 100;
      if (move > 0) {
        e.preventDefault();
        this.moveDistance = Math.pow(move, 0.9) > 0.51 ? 0.51 : Math.pow(move, 0.9);
        if (this.moveDistance > 0.5) {
          if (this.moveState === 1) { return; }
          this.moveState = 1;
        } else {
          if (this.moveState === 0) { return; }
          this.moveState = 0;
        }
      }
    },
    touchEnd () {
      if (this.moveDistance > 0.5) {
        this.moveState = 2;
        this.moveDistance = 0.5;
        this.$emit("refresh", () => {
          this.moveState = 0;
        });
      } else {
        this.moveDistance = 0;
      }
    },
    setScrollTopPos (num) {
      this.$refs.scrollBox.scrollTop = num;
    },
    onScroll (event) {
      const offsetHeight = event.currentTarget.offsetHeight,
        scrollHeight = event.target.scrollHeight,
        scrollTop = event.target.scrollTop,
        scrollBottom = offsetHeight + scrollTop;
      this.$emit("scrollBarPos", scrollTop);
      if (scrollBottom === scrollHeight || scrollBottom >= scrollHeight - 10) {
        this.$emit("scrollLoadData", event);
      }
    }
  },
  computed: {
    style () {
      return {
        top: `${this.top}rem`,
        bottom: `${this.bottom}rem`,
        backgroundColor: this.bg,
        transform: `translateY(${this.moveDistance}rem)`
      };
    }
  },
  watch: {
    moveState (val) {
      if (val == 0) {
        this.moveDistance = 0;
      }
    }
  }
};
</script>

<style scoped>
.scroll-box {
  position: absolute;
  width: 100%;
  overflow: auto;
  transition: 300ms;
  margin-top: -0.5rem;
}
.pull-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.5rem;
}
.pull-txt {
  font-size: 0.12rem;
  color: #93969d;
}
</style>
