<template>
  <div class="screen-box">
    <span @click="openPicker('start');">
      <i>{{startTime||"开始时间"}}</i>
    </span>
    <strong class="split-time">—</strong>
    <span @click="openPicker('end');">
      <i>{{endTime||"结束时间"}}</i>
    </span>
    <mt-popup v-model="popupStartDateShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupStartDateShow=false;">取消</button>
          <button @click="finishDateEvent">完成</button>
        </div>
        <mt-picker :slots="slotsDate" value-key="name" @change="onValuesDateChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
    <mt-popup v-model="popupEndDateShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupEndDateShow=false;">取消</button>
          <button @click="finishEndDateEvent">完成</button>
        </div>
        <mt-picker :slots="slotsDate" value-key="name" @change="onValuesEndDateChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-screen-time-tab",
  data () {
    return {
      popupStartDateShow: false,
      popupEndDateShow: false,
      regionInit: false,
      regionInitEnd: false,
      startTime: null,
      endTime: null,
      tempStartTime: null,
      tempEndTime: null,
      slotsDate: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }, {
        flex: 1,
        values: [{
          code: null,
          name: null
        }],
        textAlign: "center",
        defaultIndex: 0
      }, {
        flex: 1,
        values: [{
          code: null,
          name: null
        }],
        textAlign: "center",
        defaultIndex: 0
      }]
    };
  },
  props: {
  },
  mounted () {
    this.getYear();
  },
  methods: {
    openPicker (type) {
      type == "start" ? this.popupStartDateShow = true : this.popupEndDateShow = true;
    },
    getYear () {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear();
      for (let i = 2020; i <= nowYear; i++) {
        this.slotsDate[0].values.push({
          code: i,
          name: i + "年"
        });
      }
    },
    getMonth (year) {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear(),
        nowMonth = nowTime.getMonth() + 1,
        arr = [];
      const jMin = year == 2020 ? 11 : 1;
      const jMax = year == nowYear ? nowMonth : 12;
      for (let j = jMax; j >= jMin; j--) {
        arr.push({
          code: j,
          name: j + "月"
        });
      }
      return arr;
    },
    getDay (year, month) {
      const nowTime = new Date(year, month, 0),
        arr = [];
      for (let i = 1; i <= nowTime.getDate(); i++) {
        arr.push({
          code: i,
          name: i + "日"
        });
      }
      return arr;
    },
    onValuesDateChange (picker, values) {
      if (this.regionInit) {
        picker.setSlotValues(1, this.getMonth(values[0].code));
        picker.setSlotValues(2, this.getDay(values[0].code, values[1].code));
        this.tempStartTime = `${values[0].code}-${values[1].code}-${values[2].code}`;
      } else {
        this.regionInit = true;
      }
    },
    onValuesEndDateChange (picker, values) {
      if (this.regionInitEnd) {
        picker.setSlotValues(1, this.getMonth(values[0].code));
        picker.setSlotValues(2, this.getDay(values[0].code, values[1].code));
        this.tempEndTime = `${values[0].code}-${values[1].code}-${values[2].code}`;
      } else {
        this.regionInitEnd = true;
      }
    },
    finishDateEvent () {
      this.popupStartDateShow = false;
      this.startTime = this.tempStartTime;
      this.$emit("change", {
        "beginTime": this.startTime,
        "endTime": this.endTime
      });
    },
    finishEndDateEvent () {
      this.popupEndDateShow = false;
      this.endTime = this.tempEndTime;
      this.$emit("change", {
        "beginTime": this.startTime,
        "endTime": this.endTime
      });
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
.screen-box {
  height: 0.52rem;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.split-time {
  display: flex;
  align-items: center;
  justify-content: center;
}
.screen-box span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.screen-box span i {
  position: relative;
  line-height: 1;
  font-size: 0.15rem;
  color: #30353d;
  padding-right: 0.14rem;
}
.screen-box span i::before {
  position: absolute;
  top: 0.01rem;
  right: 0;
  content: '';
  width: 0.06rem;
  height: 0.06rem;
  transform: rotate(45deg);
  border-right: solid 0.01rem #99a0aa;
  border-bottom: solid 0.01rem #99a0aa;
}
</style>
