export const cachePage = {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const cachePage = vm.$store.getters.getCachePage;
      const pageName = vm.$options.name;
      cachePage.findIndex(e => e === pageName) < 0 && cachePage.push(pageName);
      vm.$store.commit("setCachePage", cachePage);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.index < from.meta.index) {
      const cachePage = [...this.$store.getters.getCachePage];
      const pageName = this.$options.name;
      cachePage.splice(cachePage.findIndex(e => e === pageName), 1);
      this.$store.commit("setCachePage", cachePage);
    }
    next();
  },
  watch: {
    $route(to, from) {
      if (to.meta.index < from.meta.index) {
        this.$store.commit("setRefresh", true);
      }
    }
  }
};
