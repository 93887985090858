<template>
  <div class="no-data">没有更多内容了 ~</div>
</template>

<script>
export default {
  name: "x-no-data",
  data () {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.no-data {
  text-align: center;
  margin: 0.46rem auto 0.4rem;
  font-size: 0.13rem;
  color: #c2c4c8;
}
</style>
