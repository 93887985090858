<template>
  <div class="no-data-bg">
    <i>
      <p>{{tip||"暂无结果"}}</p>
    </i>
  </div>
</template>

<script>
export default {
  name: "x-no-data-bg",
  data () {
    return {
    };
  },
  props: {
    tip: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {},
  components: {}
};
</script>

<style scoped>
.no-data-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.no-data-bg i {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 100%;
  height: 2.1rem;
  background: url('~@images/no_data.png') no-repeat center/1.62rem 1.48rem;
}
.no-data-bg i p {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0.15rem;
  color: #959aa8;
}
</style>
