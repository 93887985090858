export const page = {
  data() {
    return {
      xhrData: [],
      ajaxEnd: false,
      isNextPage: true,
      pageNum: 1,
      pageSize: 20
    };
  },
  methods: {
    scrollLoadData() {
      if (this.isNextPage && this.ajaxEnd) {
        this.pageNum += 1;
        this.ajaxEnd = false;
        this.getDataList(false);
      }
    }
  }
};
